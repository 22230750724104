/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import ogimage from '../../images/events/2023/nabny/NAB-NY-og-image.jpg';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2023/nabny/NAB-NY-desktop-hero.jpg';
import mobileBanner from '../../images/events/2023/nabny/NAB-NY-mobile-hero.jpg';

export default function NABNY() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (

        <Layout title='NAB Show New York 2023 - Witbe' isEvents>
            <SEO
                title='Witbe is attending NAB Show New York 2023: Proactive Monitoring Expertise'
                ogDescription='Connect with Witbe at the NAB Show New York 2023 (Oct 25-26) for advanced insights into proactive monitoring in the media and entertainment industries.'
                description='Connect with Witbe at the NAB Show New York 2023 (Oct 25-26) for advanced insights into proactive monitoring in the media and entertainment industries.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={6} banner={mobileBanner} /> : <DesktopEventPage id={6} banner={banner} />}
        </Layout>
    );
}
